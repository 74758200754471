<template>
  <section :class="classes">
    <slot />
  </section>
</template>

<script setup>
const props = defineProps({
  margins: {
    type: String,
    default: 'default'
  },
  rounded: {
    type: Boolean,
    default: false
  },
  background: {
    type: String,
    default: '#e5e7eb' // faun = '#e8e5de'
  }
})

const classes = computed(() => {
  let classString = ''
  if (props.background.includes('#')) {
    classString += ` bg-[${props.background}]`  
  } else {
    classString += ` bg-${props.background}`
  }
  if (props.margins === 'reset-top') {
    classString += ' pb-16 md:pb-24 lg:pb-32 pt-0'
  } else if (props.background !== '#e5e7eb') {
    classString += ' py-12 md:py-16'
  } else {
    classString += ' py-12 md:py-16'
  }
  if (props.background !== '#e5e7eb') {
    classString += ' rounded-[20px] md:rounded-[35px]'
  }
  return classString
})

</script>